.toolbar {

  .pages {

    .pages-label {
      display: none;
    }

    .items {
      display: flex;

      .label {
        display: none;
      }

      .item {
        margin: 0 1.2rem;

        &.current {
          background-color: rgba($gray-500, 0.1);
          display: block;
          height: 2.4rem;
          width: 2.4rem;
          border-radius: 3rem;
          color: $black;

          .page {
            font-weight: bold;
          }
        }
      }

      .page {
        font-size: 2rem;
        line-height: 1.9rem;
        font-weight: 400;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
